/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import React from "react"
import { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "@components/Layouts"
import Section from "@components/Section"
import { BodyLarge } from "@components/primitives/Text"
import TwoColumnSection, {
  Block as ColumnBlock,
  ImageBlock as ColumnImageBlock,
} from "@components/TwoColumnSection"
import CTAHeadingBlock from "@components/CTAHeadingBlock"
import SEO from "@components/seo"
import contactHeader from "@static/images/contact/contact-header.jpg"
import FieldSpacer from "@components/form/FieldSpacer"
import AccordionWithBanner from "@components/Accordion/WithBanner"
import BannerBlock from "@components/BannerBlock"
import { Grid } from "@theme-ui/components"
import SelectInput from "@components/form/SelectInput"
import Container from "@components/Container"
import { rem } from "@src/theme"
import Link from "@components/Link"
import Spacer from "@components/Spacers"
import ImpactFooter from "@components/ImpactFooter"
import { isBrowser } from "@helpers"
import { Themed } from '@theme-ui/mdx'
import CalloutTextBlocks from "@components/CalloutTextBlocks"
import ImageTextBlocksList from "@components/ImageTextBlocksList"
import { formatMarkdownToHtml } from "@helpers"
import MarkdownBody from "@modules/MarkdownBody"
import ImageGallery from "@components/ImageGallery"
import CallToAction from "@components/CallToAction"
import QuickLinks from "@components/QuickLinks"
import IconThreeUp from "@components/IconThreeUp"
import TextCarousel from "@components/TextCarousel"
import BannerCta from "@components/BannerCta"
import TestimonialCarousel from "@components/TestimonialCarousel"
import Accordion from "@components/Accordion"
import { DonateSection } from "@components/ImpactFooter"
import PartnerLogos from "@components/PartnerLogos"


import {
  Events,
  Fundraising,
  General,
  Media,
  Partnership,
  Sponsorship,
  Store,
  Volunteer,
  Workshop,
} from "@modules/ContactForms"
import Facebook from "@components/assets/svg/facebook.svg"
import Instagram from "@components/assets/svg/instagram.svg"
import Twitter from "@components/assets/svg/twitter.svg"
import LinkedIn from "@components/assets/svg/linkedin.svg"
import Youtube from "@components/assets/svg/youtube.svg"
import pageContent from "@content/contact"

export default props => {
  const data = useStaticQuery(
    graphql`
      query {
        pageData: markdownRemark(
          fileAbsolutePath: { glob: "**/pages/contact/index.md" }
        ) {
          frontmatter {
            header {
              ...Header
            }
            threeColumnTextBlock {
              heading
              columns {
                image
                heading
                blockHeading
                text
                ctaButton {
                  label
                  link
                }
              }
            }
            threeColumnTextBlockTwo {
              heading
              columns {
                image
                heading
                blockHeading
                text
                ctaButton {
                  label
                  link
                }
              }
            }
            imageGallery{
              image1
              image2
              image3
              image4
              image5
              image6
            }
            threeUpBlock {
                heading
                columns {
                    text
                }
            }
            carouselStats {
              ...CarouselStats
            }
            imageTextBlock {
              gradientBackground
                rows {
                image
                heading
                text
                links {
                  label
                  link
                }
              }
            }
            smallCta {
                image
                imageFit
                heading
                text
                backgroundImg
                ctaButton {
                    label
                    link
                }
            }
            ctaBlock {
              heading
              subheading
              text
              ctaButton {
                label
                link
              }
            }
            carousel {
                heading
                image
                text
                footnote
                ctaButton {
                    label
                    link
                }
            }
            quickLinks {
              heading
              links {
                image
                heading
                text
                link
              } 
            }
            accordion {
                ...Accordion
            }
            partnerLogos {
              heading
              text
              ctaButton {
                label
                link
              }
              partners {
                image
              }
              supporters {
                image
              }
            }
            ogTitle
            ogimage
            ogdescription
          }
        }
      }
    `
  )

  const { frontmatter } = data.pageData
  const { header,
    banner,
    ogimage,
    ogTitle,
    ogdescription,
    threeColumnTextBlock,
    threeColumnTextBlockTwo,
    imageGallery,
    imageTextBlock,
    ctaBlock,
    quickLinks,
    threeUpBlock,
    carouselStats,
    smallCta,
    carousel,
    accordion,
    partnerLogos } = frontmatter
  const [subject, setSubject] = useState("General enquiries")

  const handleSubjectChange = e => {
    setSubject(e.target.value)
  }

  return (
    <Layout>
      <SEO title={ogTitle || header?.heading} image={ogimage} ogdescription={ogdescription} />
      {banner && (
        <MarkdownBody
          html={formatMarkdownToHtml(banner)}
          sx={{
            bg: "turquoise",
            color: "darkPurple",
            textAlign: "center",
            py: rem(25),

            "h1,h2,h3,h4,h5": {
              my: 0,
            },
          }}
        />
      )}
      {header && header.image ? (<TwoColumnSection
        col1={
          <div>
            <ColumnBlock
              styles={{
                bg: "purple",
                color: "pink",
              }}
            >
              <CTAHeadingBlock heading={header.heading} />
            </ColumnBlock>
            <ColumnBlock
              styles={{
                bg: "pink",
                color: "darkPurple",
              }}
            >
              <CTAHeadingBlock
                heading={header.subheading}
                body={header.subtext}
                buttonLabel={header.ctaButton?.label}
                buttonLink={header.ctaButton?.link}
                smallHeading
              />
            </ColumnBlock>
          </div>
        }
        col2={<ColumnImageBlock image={header.image} />}
      />) : (<TwoColumnSection
        col1={
          <ColumnBlock
            styles={{
              bg: "purple",
              color: "pink",
            }}
          >
            <CTAHeadingBlock heading={header.heading} />
          </ColumnBlock>
        }
        col2={<ColumnBlock
          styles={{
            bg: "pink",
            color: "darkPurple",
          }}
        >
          <CTAHeadingBlock
            heading={header.subheading}
            body={header.subtext}
            buttonLabel={header?.ctaButton?.label}
            buttonLink={header?.ctaButton?.link}
            smallHeading
          />
        </ColumnBlock>}
      />)}
      {threeUpBlock && threeUpBlock?.columns?.length > 0 &&
        <IconThreeUp
          textBlocks={threeUpBlock.columns.map((item) => item.text)}
          heading={threeUpBlock.heading}
        />
      }
      {threeColumnTextBlock && threeColumnTextBlock.map(row => (
        <Container>
          <Section>
            <CalloutTextBlocks blocks={row.columns} heading={row.heading} />
          </Section>
        </Container>
      ))}
      {carousel && carousel.length > 0 && (
        <Section>
          <TestimonialCarousel testimonials={carousel} />
        </Section>)}
      {smallCta && smallCta.heading && <BannerCta
        heading={smallCta.heading}
        text={smallCta.text}
        imageFit={smallCta.imageFit}
        cta={smallCta?.ctaButton && {
          url: smallCta?.ctaButton?.link,
          text: smallCta?.ctaButton?.label,
        }}
        img={{
          src: smallCta.image,
          alt: smallCta.heading,
        }}
        backgroundImg={smallCta.backgroundImg}
      />}

      {imageTextBlock && imageTextBlock?.rows?.length > 0 && (
        imageTextBlock.gradientBackground ?
          (<GradientWrapper>
            <ImageTextBlocksList blocks={imageTextBlock.rows} />
          </GradientWrapper>
          ) :
          (<ImageTextBlocksList blocks={imageTextBlock.rows} />)
      )}
      {quickLinks && <QuickLinks heading={quickLinks.heading} quickLinks={quickLinks.links} />}
      {carouselStats && carouselStats.length && <Section>
        <TextCarousel blocks={carouselStats} />
      </Section>}
      {threeColumnTextBlockTwo && threeColumnTextBlockTwo.map(row => (
        <Container>
          <Section>
            <CalloutTextBlocks blocks={row.columns} heading={row.heading} />
          </Section>
        </Container>
      ))}
      {accordion && accordion?.accordionItems?.length > 0 && (
        <>
          <Spacer.Medium />
          <Accordion heading={accordion.heading} items={accordion.accordionItems} />
          <Spacer.Medium />
        </>
      )}
      {partnerLogos && <PartnerLogos {...partnerLogos} />}
      {imageGallery && Object.values(imageGallery).filter((value) => value !== "").length > 0 && (
        <ImageGallery {...imageGallery} />
      )}
      <CallToAction {...ctaBlock} />

      <div
        sx={{
          bg: "pink",
        }}
        id="contact-form"
      >
        <Section>
          <BannerBlock title="Something else?" noMb />
          <div
            sx={{
              bg: "white",
              pt: rem(70),
              pb: rem(80),
              maxWidth: rem(842),
              mx: "auto",
            }}
          >
            <div
              sx={{
                width: "85%",
                mx: "auto",
              }}
            >
              <FieldSpacer>
                <SelectInput
                  name="subject"
                  value={subject}
                  onChange={handleSubjectChange}
                  label="Subject"
                  placeholder="Select"
                  onBlur={() => { }}
                  error={false}
                  options={pageContent.subjectOptions}
                />
              </FieldSpacer>
              <Events activeSubject={subject} />
              <Fundraising activeSubject={subject} />
              <General activeSubject={subject} />
              <Media activeSubject={subject} />
              <Partnership activeSubject={subject} />
              <Sponsorship activeSubject={subject} />
              <Store activeSubject={subject} />
              <Volunteer activeSubject={subject} />
              <Workshop activeSubject={subject} />
            </div>
          </div>
        </Section>
      </div>

      {/* <ImpactFooter noPt noPb /> */}
    </Layout>
  )
}
