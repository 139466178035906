/** @jsx jsx */
import { jsx } from "theme-ui"
import { useState } from "react"
import axios from "axios"
import qs from "query-string"
import { Formik } from "formik"
import styled from "styled-components"
import get from "lodash/get"
import TextInput from "@components/form/TextInput"
import TextArea from "@components/form/TextArea"
import { rem } from "@src/theme"
import Button from "@components/Button"
import * as Yup from "yup"
import FieldSpacer from "@components/form/FieldSpacer"

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Must be a valid email")
    .required("This is required"),
})

const initialFormState = {
  name: "",
  phone: "",
  email: "",
  message: "",
}

export default ({ formName = "General enquiries", activeSubject }) => {
  const [submissionError, setSubmissionError] = useState(null)
  const [success, setSuccess] = useState(null)
  const [loading, setLoading] = useState(false)
  const getError = (name, errors, touched) =>
    get(touched, name) && get(errors, name) ? get(errors, name) : ""

  return (
    <Hider active={activeSubject === formName}>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialFormState}
        onSubmit={(values, actions) => {
          const formData = {}
          Object.keys(values).map(key => (formData[key] = values[key]))

          formData["form-name"] = formName
          const axiosOptions = {
            url: "/contact",
            method: "post",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            data: qs.stringify(formData),
          }

          setLoading(true)

          axios(axiosOptions)
            .then(response => {
              if (!response) {
                throw new Error()
              }
              if (submissionError || success) {
                setSubmissionError(null)
                setSuccess(null)
              }
              setLoading(false)
              setSuccess("We'll be in touch!")
              actions.resetForm(initialFormState)
            })
            .catch(err => {
              setSubmissionError(
                "There was a problem handling your submission, please contact us via email at info@minus18.org.au or if its urgent, call us on 0478 098 540."
              )
            })
        }}
      >
        {({
          handleBlur,
          handleChange,
          submitForm,
          values,
          errors,
          touched,
        }) => (
          <Form
            name={formName}
            method="post"
            data-netlify="true"
            onSubmit={e => {
              e.preventDefault()
              submitForm()
            }}
          >
            <FieldSpacer>
              <input type="hidden" name="form-name" value={formName} />
              <TextInput
                value={values.name}
                label="Name"
                name="name"
                onChange={handleChange}
                error={getError("name", errors, touched)}
                onBlur={handleBlur}
              />

              <TextInput
                value={values.phone}
                label="Phone"
                name="phone"
                onChange={handleChange}
                error={getError("phone", errors, touched)}
                onBlur={handleBlur}
              />
            </FieldSpacer>
            <FieldSpacer>
              <TextInput
                value={values.email}
                label="Email"
                name="email"
                onChange={handleChange}
                error={getError("email", errors, touched)}
                onBlur={handleBlur}
                required
              />
            </FieldSpacer>

            <FieldSpacer>
              <TextArea
                value={values.message}
                label="Message"
                name="message"
                onChange={handleChange}
                error={getError("message", errors, touched)}
                onBlur={handleBlur}
              />
            </FieldSpacer>

            <div>
              <Button type="submit" disabled={submissionError || loading}>
                {loading ? "..." : "Submit enquiry"}
              </Button>
            </div>

            {submissionError && (
              <p sx={{ color: "red", maxWidth: rem(500) }}>{submissionError}</p>
            )}
            {success && (
              <p sx={{ color: "green", maxWidth: rem(500) }}>{success}</p>
            )}
          </Form>
        )}
      </Formik>
    </Hider>
  )
}

const Form = styled.form`
  label {
    margin-bottom: ${rem(33)};
  }
`

const Hider = styled.div`
  display: ${p => (p.active ? "block" : "none")};
`
