/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import styled from "styled-components"
import ButtonLink from "@components/ButtonLink"
import theme, { rem, articleStyles } from "@src/theme"
import IconLink from "@components/IconLink"
import {
  Accordion as LibraryAccordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"
import "react-accessible-accordion/dist/fancy-example.css"
import { formatMarkdownToHtml } from "@helpers"
import { Themed } from "@theme-ui/mdx"

const Panel = ({
  id,
  body,
  heading,
  ctaLabel,
  ctaLink,
  image,
  colorSet,
  links,
  noMaxWidth = false,
}) => {
  return (
    <PanelStyleWrapper
      sx={{
        bg: colorSet.bg,
        color: colorSet.text,
        maxWidth: noMaxWidth ? "unset" : ["100%", null, null, "66%"],
        mx: noMaxWidth ? "auto" : [rem(48), null, null, "auto"],
        border: `1px solid ${theme.colors[colorSet.text]}`,
        borderBottom: "none",
        ":last-child": {
          borderBottom: `1px solid ${theme.colors[colorSet.text]}`,
        },
      }}
      colorSet={colorSet}
    >
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>{heading}</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <div
            sx={{
              display: "flex",
              flexDirection: ["column", null, "row"],
            }}
          >
            <div
              className="content-inner"
              sx={{
                color: colorSet.text,
                pt: 0,
                pr: [0, null, rem(48), null],
                width: image ? ["90%", null, "50%"] : "90%",
              }}
            >
              <div
                dangerouslySetInnerHTML={{ __html: body }}
                sx={{
                  ...articleStyles,
                  pb: 0,
                }}
              />
              {ctaLink && ctaLabel && (
                <div
                  sx={{
                    mt: [0, null, rem(48)],
                    mb: links && links.length ? rem(20) : 0,
                  }}
                >
                  <ButtonLink
                    to={ctaLink}
                    styles={{
                      bg:
                        colorSet.bg === "darkPurple" ? "purple" : "darkPurple",

                      "&:hover": {
                        bg:
                          colorSet.bg === "purple" ||
                          colorSet.bg === "darkPurple"
                            ? "white"
                            : "purple",
                        color:
                          colorSet.bg === "purple" ||
                          colorSet.bg === "darkPurple"
                            ? "darkPurple"
                            : "white",
                      },
                    }}
                  >
                    {ctaLabel}
                  </ButtonLink>
                </div>
              )}
              {links ? (
                <LinksWrapper
                  sx={{
                    display: "flex",
                    flexDirection: ["column", "row"],
                  }}
                >
                  {links.map((item, i) => (
                    <div key={i}>
                      <IconLink {...item} type="arrowRight" />
                    </div>
                  ))}
                </LinksWrapper>
              ) : null}
            </div>

            {image && (
              <div
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: ["90%", null, "50%"],
                  mt: [rem(48), null, 0],
                }}
              >
                {image && (
                  <img
                    src={image}
                    alt=""
                    sx={{
                      width: "100%",
                    }}
                  />
                )}
              </div>
            )}
          </div>
        </AccordionItemPanel>
      </AccordionItem>
    </PanelStyleWrapper>
  )
}
const Accordion = ({ items, heading }) => {
  if (heading) {
    return (
      <AccordionStylesWrapper>
        <div
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            maxWidth: ["100%", null, null, "66%"],
            px: [rem(48), null, null, 0],
            margin: "auto",
            flexWrap: "wrap",
          }}
        >
          <Themed.h2
            sx={{
              mb: rem(48),
              mt: 0,
              width: ["100%", null, null, `calc(40% - ${rem(50)})`],
              mr: rem(50),
            }}
          >
            {heading}
          </Themed.h2>
          <LibraryAccordion
            allowZeroExpanded
            sx={{
              width: ["100%", null, null, "60%"],
            }}
          >
            {items.map((item, i) => (
              <Panel
                noMaxWidth
                key={item.heading}
                {...item}
                body={formatMarkdownToHtml(item.body)}
                colorSet={colorSets[i % 4]}
              />
            ))}
          </LibraryAccordion>
        </div>
      </AccordionStylesWrapper>
    )
  }

  return (
    <AccordionStylesWrapper>
      <LibraryAccordion allowZeroExpanded>
        {items?.map &&
          items.map((item, index) => (
            <Panel
              key={item.heading}
              {...item}
              body={formatMarkdownToHtml(item.body)}
              colorSet={colorSets[index % 4]}
            />
          ))}
      </LibraryAccordion>
    </AccordionStylesWrapper>
  )
}

/**
 * @todo, button bg prop
 */
const colorSets = [
  {
    bg: "white",
    heading: "darkPurple",
    text: "darkPurple",
  },
  {
    bg: "white",
    heading: "darkPurple",
    text: "darkPurple",
  },
  {
    bg: "white",
    heading: "darkPurple",
    text: "darkPurple",
  },
  {
    bg: "white",
    heading: "darkPurple",
    text: "darkPurple",
  },
]

export default Accordion

Accordion.propTypes = {
  items: PropTypes.array,
}

const LinksWrapper = styled.div`
  div:not(:last-child) {
    margin-right: ${rem(47)};
  }
`
const AccordionStylesWrapper = styled.div`
  .accordion {
    border: none;
  }

  .accordion__button {
    font-family: ${theme.fonts.heading};
    font-weight: 900;

    @media only screen and (max-width: ${theme.breakpoints[0]}) {
      font-size: ${rem(18)};
    }

    @media only screen and (min-width: ${theme
        .breakpoints[0]}) and (max-width: ${theme.breakpoints[1]}) {
      font-size: ${rem(32)};
    }

    @media only screen and (min-width: ${theme
        .breakpoints[1]}) and (max-width: ${theme.breakpoints[2]}) {
      font-size: ${rem(32)};
    }
    @media only screen and (min-width: ${theme.breakpoints[2]}) {
      font-size: ${rem(32)};
    }

    &:hover {
      background-color: transparent;
    }
  }
  .accordion__button:after {
    display: inline-block;
    content: "+";
    margin-right: 12px;
    text-align: right;
    float: right;
  }

  .accordion__button[aria-expanded="true"]::after,
  .accordion__button[aria-selected="true"]::after {
    content: "−";
  }

  .accordion__button:before {
    display: none;
  }
`

const PanelStyleWrapper = styled.div`
  .accordion__button {
    color: ${p => `${theme.colors[p.colorSet.heading]}!important`};
    background: ${p => `${theme.colors[p.colorSet.bg]}!important`};
    /* text-transform: uppercase; */

    @media only screen and (max-width: ${theme.breakpoints[0]}) {
      padding: 1vw;
    }
    @media only screen and (min-width: ${theme.breakpoints[0]}) {
      padding: 1vw;
    }
  }

  .accordion__panel {
    @media only screen and (max-width: ${theme.breakpoints[0]}) {
      padding: 4vw;
    }
    @media only screen and (min-width: ${theme.breakpoints[0]}) {
      padding: 2.5vw;
    }
    padding-top: 0 !important;

    p,
    li {
      a {
        color: ${p => `${theme.colors[p.colorSet.text]}`};

        &:hover {
          color: ${p => `${theme.colors.pink}`};
        }
      }
    }
  }
`
