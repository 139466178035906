const supportContactBlocks = [
  {
    heading: "Qlife",
    body:
      "QLife provides Australia-wide LGBTQIA+ peer support and referrals for people wanting to talk about a range of issues, including sexuality, identity, gender, bodies, feelings, or relationships.",
    phone: "1800 184 527",
    link: {
      link: "https://qlife.org.au/",
      external: false,
      label: "Visit website",
    },
  },
  {
    heading: "Lifeline",
    body:
      "Lifeline is a 24-hour crisis support and suicide prevention helpline. Lifeline is available Australia-wide, 24 hours a day, for anyone experiencing a personal crisis.",
    phone: "13 11 14",
    link: {
      link: "https://www.lifeline.org.au/",
      external: false,
      label: "Visit website",
    },
  },
  {
    heading: "Kids helpline",
    body:
      "Kids Helpline provides phone counselling and crisis support to young people: kids, teens, and young adults. They also offer heaps of online resources for youth, parents, and carers.",
    phone: "1800 55 1800",
    link: {
      link: "https://kidshelpline.com.au/",
      external: false,
      label: "Visit website",
    },
  },
]

const subjectOptions = [
  { label: "General", value: "General enquiries" },
  // { label: "Workshops and training", value: "Workshop enquiries" },
  // {
  //   label: "Events (perform or enquiry about an event)",
  //   value: "Event enquiries",
  // },
  // { label: "Partnerships", value: "Partnership enquiries" },
  // {
  //   label: "Sponsorship or donations",
  //   value: "Sponsorship/Donation enquiries",
  // },
  // {
  //   label: "Fundraising",
  //   value: "Fundraising enquiries",
  // },
  { label: "Online store or resources", value: "Store/Resources enquiries" },
  // { label: "Volunteering", value: "Volunteer enquiries" },
  { label: "Media", value: "Media enquiries" },
]

export default {
  supportContactBlocks,
  subjectOptions,
}
