/** @jsx jsx */
import { jsx } from "theme-ui"
import Carousel, { CarouselTextCallout } from "@components/Carousel"
import PropTypes from "prop-types"

const TextCarousel = ({ blocks }) => (
  <Carousel
    containerStyle={{
      width: "80%",
      maxWidth: 800,
      mx: "auto",
    }}
    settings={{
      arrows: true,
    }}
  >
    {blocks.map(({ heading, text }, index) => (
      <CarouselTextCallout key={index} heading={heading}>
        {text}
      </CarouselTextCallout>
    ))}
  </Carousel>
)

export default TextCarousel

TextCarousel.propTypes = {
  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      text: PropTypes.string,
    })
  ),
}
