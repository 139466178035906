/** @jsx jsx */
import { jsx } from "theme-ui"

import Container from "@modules/ContactForms/container"

export const Events = ({ activeSubject }) => {
  return <Container formName="Event enquiries" activeSubject={activeSubject} />
}
export const General = ({ activeSubject }) => {
  return (
    <Container formName="General enquiries" activeSubject={activeSubject} />
  )
}
export const Media = ({ activeSubject }) => {
  return <Container formName="Media enquiries" activeSubject={activeSubject} />
}
export const Partnership = ({ activeSubject }) => {
  return (
    <Container formName="Partnership enquiries" activeSubject={activeSubject} />
  )
}
export const Sponsorship = ({ activeSubject }) => {
  return (
    <Container
      formName="Sponsorship/Donation enquiries"
      activeSubject={activeSubject}
    />
  )
}
export const Store = ({ activeSubject }) => {
  return (
    <Container
      formName="Store/Resources enquiries"
      activeSubject={activeSubject}
    />
  )
}
export const Volunteer = ({ activeSubject }) => {
  return (
    <Container formName="Volunteer enquiries" activeSubject={activeSubject} />
  )
}
export const Workshop = ({ activeSubject }) => {
  return (
    <Container formName="Workshop enquiries" activeSubject={activeSubject} />
  )
}
export const Fundraising = ({ activeSubject }) => {
  return (
    <Container formName="Fundraising enquiries" activeSubject={activeSubject} />
  )
}
