/** @jsx jsx */
import { jsx } from "theme-ui"
import Section from "@components/Section"
import Accordion from "@components/Accordion"
import BannerBlock from "@components/BannerBlock"

const AccordionWithBanner = ({ items, title, noMaxWidthBanner }) => (
  <Section>
    <BannerBlock title={title} noMaxWidth={noMaxWidthBanner} />
    <div
      sx={{
        px: "5vw",
      }}
    >
      <Accordion items={items} />
    </div>
  </Section>
)

export default AccordionWithBanner
